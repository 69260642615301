import React, { useState, useEffect, useRef } from "react";
import styles from "../../styles/redesignedWinner.module.sass";
import axios from "axios";
import WinAnimation from "./Animation";

const RedesignedWinnerPage = ({ win = 20000, code }) => {
  const [sertCode, setSertCode] = useState("");
  const [isAnimation, setIsAnimation] = useState(true);
  const bonusParagraphRef = useRef(null);

  useEffect(() => {
    axios
      .put("https://db.millionpuzzle.ru/codes/claim", {
        firstname: "",
        email: "",
        lastname: "",
        phone: "79999999999",
        code: code,
      })
      .then(() => {
        axios
          .put("https://db.millionpuzzle.ru/pay/phone", {
            code: code,
            phone: "9999999999",
          })
          .then((res) => {
            setSertCode(res.data.sertCode);
          });
      });
  }, [code]);

  function mtSt(marginTop) {
    return {
      marginTop: `${marginTop}px`,
    };
  }

  if (isAnimation) {
    return (
      <WinAnimation win={win} closeAnimation={() => setIsAnimation(false)} />
    );
  }

  return (
    <div className={styles.redesignedWinner}>
      <div style={mtSt(20)} className={styles.column}>
        <h1>ВАШ ВЫИГРЫШ</h1>
        <h1 className={styles.textPurpleToPink}>
          <span>{win}Р</span>
        </h1>
        <div style={mtSt(25)} className={styles.block}>
          <h2>1. СКОПИРУЙТЕ КОД АКТИВАЦИИ</h2>
          <div style={mtSt(20)} className={styles.copyCode}>
            <p>{sertCode}</p>
            <img
              src="/images/copy.svg"
              alt="copy-icon"
              onClick={() => {
                if (navigator.clipboard) {
                  navigator.clipboard.writeText(sertCode).catch((err) => {
                    console.error("Failed to copy text: ", err);
                  });
                } else {
                  const textArea = document.createElement("textarea");
                  textArea.value = sertCode;

                  textArea.style.position = "fixed";
                  textArea.style.left = "-999999px";
                  textArea.style.top = "-999999px";
                  document.body.appendChild(textArea);

                  textArea.focus();
                  textArea.select();

                  try {
                    document.execCommand("copy");
                  } catch (err) {
                    console.error("Failed to copy text: ", err);
                  }

                  document.body.removeChild(textArea);
                }
              }}
            />
          </div>
          <h2 style={mtSt(25)}>2. ПЕРЕЙДИТЕ ПО ССЫЛКЕ</h2>
          <a
            style={mtSt(20)}
            href="https://mygift.ru/?popup=%2Factivation"
            className={styles.colorfulLink}
          >
            ЗАБРАТЬ ВЫИРЫШ
          </a>
          <h2 style={mtSt(25)}>3. ВВЕДИТЕ КОД НА ОТКРЫВШЕЙСЯ СТРАНИЦЕ</h2>
          <img
            className={styles.present}
            src="/images/present.png"
            alt="present"
          />
        </div>
        <div style={mtSt(20)} className={styles.info}>
          <h2 ref={bonusParagraphRef}>
            ОСТАВЬТЕ ОТЗЫВ И ПОЛУЧИТЕ БОНУСНЫХ <span>100 РУБЛЕЙ</span> НА
            ТЕЛЕФОН!
          </h2>
          <p style={mtSt(15)}>
            Если вы остались довольны нашим товаром, опубликуйте положительный
            отзыв на Wildberries и пришлите скриншот (находится в разделе
            «Отзывы и вопросы» личного кабинета) нам в службу поддержки в
            Telegram.
          </p>
          <h2 style={mtSt(50)}>
            ПОСЛЕ ПРОВЕРКИ ВЫ СРАЗУ ЖЕ ПОЛУЧИТЕ БОНУСНЫЕ <span>100 РУБЛЕЙ</span>{" "}
            НА СЧЕТ МОБИЛЬНОГО ТЕЛЕФОНА.
          </h2>
          <a
            style={mtSt(20)}
            href="https://t.me/millionpuzzle"
            className={styles.colorfulLink}
          >
            ПОЛУЧИТЬ 100 РУБЛЕЙ
          </a>
          <h2 style={mtSt(50)}>
            ЕСТЬ ПРОБЛЕМЫ С КАЧЕСТВОМ ТОВАРА ИЛИ ПОЛУЧЕНИЕМ ВЫИГРЫША?
          </h2>
          <p style={mtSt(15)}>
            Не спешите писать плохой отзыв. Отправьте как можно более подробное
            описание вашей проблемы, и мы обязательно разберемся.
          </p>
          <a
            style={mtSt(20)}
            href="https://t.me/millionpuzzle"
            className={styles.darkLink}
          >
            СВЯЗАТЬСЯ С ПОДДЕРЖКОЙ
          </a>
          <img className={styles.money} src="/images/5k.png" alt="money" />
        </div>
      </div>
      <div className={styles.decor}>
        <img
          src="/images/money-1.png"
          alt="money-1"
          className={styles.firstMoney}
        />
        <img
          src="/images/money-2.png"
          alt="money-2"
          className={styles.secondMoney}
        />
        <img
          src="/images/money-3.png"
          alt="money-3"
          className={styles.thirdMoney}
        />
      </div>
      <div
        className={styles.yourWin}
        onClick={() => {
          bonusParagraphRef.current.scrollIntoView({ behavior: "smooth" });
        }}
      >
        <p>ПОЛУЧИТЬ БОНУС</p>
        <p>
          <span>100Р</span>
        </p>
      </div>
    </div>
  );
};

export default RedesignedWinnerPage;
