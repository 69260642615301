import { useEffect, useRef, useState } from "react";
import formStyles from "../../styles/forms.module.sass";
import styles from "../../styles/validation.module.sass";
import InputMask from "react-input-mask";
import axios from "axios";
import WinnerPage from "./Winner";
import { ErrorScreen } from "../errorScreen/error";
import RedesignedWinnerPage from "./RedesignedWinner";
const ip = process.env.REACT_APP_IP;

const ValidationForm = ({ link }) => {
  const [inpuRef, setInputFocus] = useFocus();
  const ref = useRef();
  const [focusIndex, setFocusIndex] = useState(1);
  const [validationCode, setValidationCode] = useState("");
  const [code, setCode] = useState("");
  const [winners, setWinner] = useState({
    loaded: false,
    win: null,
    auth: false,
  });
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    setInputFocus();
    const nextSibling = document.querySelector(`input[name=code${focusIndex}]`);
    if (nextSibling) {
      nextSibling.focus();
    }
  }, [focusIndex]);

  function mtSt(marginTop) {
    return {
      marginTop: `${marginTop}px`,
    };
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const validation = validationCode.split("-").join("");
    // console.log(validation)
    ValidateCode({ validation, link }).then((res) => {
      if (res.status) {
        setWinner({ loaded: true, win: res.value, auth: true });
      } else {
        setWinner({ loaded: true, auth: false });
        setErrMsg(res.msg);
      }
    });
  };

  return (
    <div className={styles.validation}>
      {!winners.loaded ? (
        <div className={styles.column}>
          <h1 style={mtSt(20)}>ПОСЛЕДНИЙ ШАГ</h1>
          <h2 style={mtSt(20)}>
            ДЛЯ ПОЛУЧЕНИЯ ПРИЗА ОСТАЛОСЬ ВВЕСТИ ВАЛИДАЦИОННЫЙ КОД, НАПИСАННЫЙ НА
            ИНСТРУКЦИИ
          </h2>
          <p style={mtSt(10)}>
            Пожалуйста, сделайте скриншот выигрыша на следующей странице
          </p>
          <form style={mtSt(20)} onSubmit={onSubmit}>
            <InputMask
              mask="****-****-****-****"
              alwaysShowMask={false}
              value={validationCode}
              onChange={(e) => setValidationCode(e.target.value)}
            >
              {(inputProps) => (
                <input
                  {...inputProps}
                  // type="tel"
                  disableUnderline
                  ref={ref}
                  type="text"
                  name="code"
                  placeholder={"-"}
                  autoCapitalize="none"
                  autoComplete="none"
                />
              )}
            </InputMask>
            <p style={mtSt(20)}>
              Пожалуйста, обратите внимание что буква О и цифра 0 в инструкции
              очень похожи. Если у вас не подходит код, то попробуйте разные
              комбинации символов.
            </p>
            <h2 style={mtSt(50)}>
              ЕСТЬ ПРОБЛЕМЫ С КАЧЕСТВОМ ТОВАРА ИЛИ ПОЛУЧЕНИЕМ ВЫИГРЫША?
            </h2>
            <p style={mtSt(15)}>
              Не спешите писать плохой отзыв. Отправьте как можно более
              подробное описание вашей проблемы, и мы обязательно разберемся.
            </p>
            <a
              style={mtSt(20)}
              href="https://t.me/millionpuzzle"
              className={styles.darkLink}
            >
              СВЯЗАТЬСЯ С ПОДДЕРЖКОЙ
            </a>
            <button style={mtSt(50)} type="submit">
              ВВЕСТИ КОД
            </button>
          </form>
        </div>
      ) : winners.auth ? (
        <RedesignedWinnerPage
          win={winners.win}
          code={validationCode.split("-").join("")}
        />
      ) : (
        <div className={styles.column}>
          <h1 style={mtSt(20)}>ОШИБКА</h1>
          <h2 style={mtSt(20)}>{errMsg}</h2>
          <button
            style={mtSt(40)}
            onClick={() => setWinner({ loaded: false, win: null, auth: null })}
          >
            ПОВТОРИТЬ
          </button>
        </div>
      )}
    </div>
  );
};

export default ValidationForm;

const ValidateCode = async ({ validation, link }) => {
  try {
    const code = {
      code: validation,
    };
    const res = await axios.put(ip + `codes/win/${link}`, code);
    return res.data;
  } catch (err) {
    const res = {
      msg: err.response.data.err,
      status: err.response.data.status,
    };
    return res;
  }
};

///focus hook
const useFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
};
