import axios from "axios";
import { useEffect, useRef, useState } from "react";
import WinAnimation from "./Animation";
import styles from "../../styles/winner.module.sass";
import InputMask from "react-input-mask";
import { ErrorScreen, OtherErrorScreen } from "../errorScreen/error";
import Textfit from "react-textfit";
import NdflWinner from "../finishScreens/ndflWinner";
import Header from "../text/header";
import SubHeader from "../text/subHeader";
import Gap from "../layout/gap";
import Select, { components } from "react-select";

const ip = process.env.REACT_APP_IP;

const CardPage = ({ win, code, totalSum, counter, phone }) => {
  const [error, setError] = useState(false);
  const [sertCode, setSertCode] = useState(null);

  const sendOnPhone = () => {
    const phoneNumber = phone.replace(/\D/g, "").substring(1);
    EnterPhone(code, phoneNumber).then((res) => {
      if (res.status) {
        console.log(res);
        setSertCode(res.sertCode);
      } else {
        setError(res.error);
      }
    });
  };
  const closeTab = () => {
    window.open("about:blank", "_self");
    window.close();
  };

  useEffect(() => {
    sendOnPhone();
  }, []);

  return (
    <div className={styles.cardPage}>
      {win <= 4000 &&
        (error ? (
          <OtherErrorScreen error={error} onClick={() => setError(false)} />
        ) : totalSum ? (
          <NdflWinner closeTab={closeTab} counter={counter} />
        ) : (
          <>
            <Header text={"Мои данные"} />
            <SubHeader
              text={`Скопируйте код активации (копируется по клику):`}
            />
            <SubHeader
              text={sertCode}
              onClick={(e) => {
                console.log("ok");
                navigator.clipboard.writeText(sertCode);
              }}
            />
            <SubHeader text={"Перейдите по ссылке:"} />
            <a href="https://mygift.ru/?popup=%2Factivation">
              https://mygift.ru/?popup=%2Factivation
            </a>
            <SubHeader text={"И введите код на открывшейся страничке"} />
            <Gap height={100} />
            <button onClick={closeTab}>ЗАКРЫТЬ</button>
          </>
        ))}

      {win > 4000 && <NdflWinner closeTab={closeTab} counter={counter} />}
    </div>
  );
};

export default CardPage;

const EnterPhone = async (code, phone) => {
  try {
    const formData = {
      code: code,
      phone: phone,
    };
    const res = await axios.put(ip + `pay/phone`, formData);
    console.log("phone");
    return { status: true, sertCode: res.data.sertCode };
  } catch (err) {
    // alert(err.response.data.err)
    return {
      status: false,
      error: err.response ? err.response.data.msg : err.message,
    };
  }
};
