// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout_layout__sql64 {
  display: flex;
  justify-content: space-between;
  z-index: 9999;
}
.layout_layout__sql64 address {
  font-family: Pragmatica-Extended, sans-serif;
  font-weight: 1000;
  font-size: 14px;
  color: white;
  text-transform: none;
  font-style: normal;
}
.layout_layout__sql64 a {
  font-family: Pragmatica-Extended, sans-serif;
  font-weight: 1000;
  font-size: 14px;
  width: max-content;
  text-decoration: none;
  padding-left: 7px;
  padding-right: 7px;
  color: white;
  text-transform: none;
}`, "",{"version":3,"sources":["webpack://./src/styles/layout.module.sass"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,8BAAA;EACA,aAAA;AADJ;AAGI;EACI,4CAAA;EACA,iBAAA;EACA,eAAA;EACA,YAAA;EACA,oBAAA;EACA,kBAAA;AADR;AAGI;EACI,4CAAA;EACA,iBAAA;EACA,eAAA;EACA,kBAAA;EACA,qBAAA;EACA,iBAAA;EACA,kBAAA;EACA,YAAA;EACA,oBAAA;AADR","sourcesContent":["@use 'base'\n\n.layout\n    display: flex\n    justify-content: space-between\n    z-index: 9999\n\n    address\n        font-family: Pragmatica-Extended, sans-serif\n        font-weight: 1000\n        font-size: 14px\n        color: white\n        text-transform: none\n        font-style: normal\n\n    a\n        font-family: Pragmatica-Extended, sans-serif\n        font-weight: 1000\n        font-size: 14px\n        width: max-content\n        text-decoration: none\n        padding-left: 7px\n        padding-right: 7px\n        color: white\n        text-transform: none\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": `layout_layout__sql64`
};
export default ___CSS_LOADER_EXPORT___;
