// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  height: 100%;
}
body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


::-webkit-scrollbar {
	width: 0px !important;
	margin: 0px !important;
	height: 0px !important;
}


::-webkit-scrollbar-track {
	background-color: black;
}

/* @font-face {
  font-family: RussoOne-Regular;
  src: url(./styles/fonts/RussoOne-Regular.ttf);
  src: url(./styles/fonts/RussoOne-Regular.woff);


  }
@font-face {
  font-family: Anton-Regular;
  src: url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
  src: url(./styles/fonts/Anton-Regular.ttf);
  src: url(./styles/fonts/Anton-Regular.woff);

  } */
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;AACA;EACE,SAAS;EACT,YAAY;EACZ;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;EAClC,uBAAuB;AACzB;;AAEA;EACE;aACW;AACb;;;AAGA;CACC,qBAAqB;CACrB,sBAAsB;CACtB,sBAAsB;AACvB;;;AAGA;CACC,uBAAuB;AACxB;;AAEA;;;;;;;;;;;;;KAaK","sourcesContent":["html {\n  height: 100%;\n}\nbody {\n  margin: 0;\n  height: 100%;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background-color: black;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n\n::-webkit-scrollbar {\n\twidth: 0px !important;\n\tmargin: 0px !important;\n\theight: 0px !important;\n}\n\n\n::-webkit-scrollbar-track {\n\tbackground-color: black;\n}\n\n/* @font-face {\n  font-family: RussoOne-Regular;\n  src: url(./styles/fonts/RussoOne-Regular.ttf);\n  src: url(./styles/fonts/RussoOne-Regular.woff);\n\n\n  }\n@font-face {\n  font-family: Anton-Regular;\n  src: url('https://fonts.googleapis.com/css2?family=Anton&display=swap');\n  src: url(./styles/fonts/Anton-Regular.ttf);\n  src: url(./styles/fonts/Anton-Regular.woff);\n\n  } */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
