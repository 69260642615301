// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.errors_errorScreen__k7Yxa {
  font-family: Pragmatica-Extended, sans-serif;
  font-weight: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.errors_errorScreen__k7Yxa .errors_column__GSR6H {
  width: 600px;
  max-width: calc(100% - 40px);
  padding: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.errors_errorScreen__k7Yxa .errors_column__GSR6H h1 {
  font-size: 2.5rem;
  color: white;
  width: 100%;
  line-height: 1;
  text-align: center;
  margin-top: 20px;
}
.errors_errorScreen__k7Yxa .errors_column__GSR6H .errors_error__FUCqS {
  max-width: calc(100% - 40px);
  height: 300px;
  margin-top: 40px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: linear-gradient(to right, #0e0e0e, #1b1b1b, #0e0e0e) padding-box, linear-gradient(to right, #391863, #f80943, #391863) border-box;
  border: 1px solid transparent;
  border-radius: 5px;
  color: white;
  font-size: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/styles/errors.module.sass"],"names":[],"mappings":"AAAA;EACE,4CAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AACF;AACE;EACE,YAAA;EACA,4BAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AACJ;AACI;EACE,iBAAA;EACA,YAAA;EACA,WAAA;EACA,cAAA;EACA,kBAAA;EACA,gBAAA;AACN;AACI;EACE,4BAAA;EACA,aAAA;EACA,gBAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,6IAAA;EACA,6BAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;AACN","sourcesContent":[".errorScreen\n  font-family: Pragmatica-Extended, sans-serif\n  font-weight: 1000\n  display: flex\n  flex-direction: column\n  align-items: center\n  justify-content: center\n\n  .column\n    width: 600px\n    max-width: calc(100% - 40px)\n    padding: 20px\n    flex-direction: column\n    align-items: center\n    justify-content: center\n\n    h1\n      font-size: 2.5rem\n      color: white\n      width: 100%\n      line-height: 1\n      text-align: center\n      margin-top: 20px\n\n    .error\n      max-width: calc(100% - 40px)\n      height: 300px\n      margin-top: 40px\n      padding: 20px\n      display: flex\n      flex-direction: column\n      align-items: center\n      justify-content: center\n      text-align: center\n      background: linear-gradient(to right, #0e0e0e, #1b1b1b, #0e0e0e) padding-box, linear-gradient(to right, #391863, #f80943, #391863) border-box\n      border: 1px solid transparent\n      border-radius: 5px\n      color: white\n      font-size: 2rem\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorScreen": `errors_errorScreen__k7Yxa`,
	"column": `errors_column__GSR6H`,
	"error": `errors_error__FUCqS`
};
export default ___CSS_LOADER_EXPORT___;
