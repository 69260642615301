import React from "react";
import styles from "../../styles/errors.module.sass";
import Textfit from "react-textfit";
import Header from "../text/header";
import SubHeader from "../text/subHeader";
import Gap from "../layout/gap";

export const errorMessages = {
  "Что-то пошло не так": (
    <div>
      <SubHeader text={"Автоматически выплаты проходили через QIWI банк"} />
      <Gap height={10} />
      <SubHeader
        text={
          "Из-за отзыва у QIWI лицензии  автоматические выплаты временно недоступны."
        }
      />
      <Gap height={10} />
      <SubHeader text={"Пожалуйста, напишите письмо на "} />
      <SubHeader text={"info@millionpuzzle.ru "} />
      <SubHeader
        text={
          "со скриншотом выигрыша и номером телефона куда осуществить выплату"
        }
      />
    </div>
  ),
  "Для продолжения требуется согласиться с условиями": (
    <div>
      <SubHeader text={"Для продолжения требуется согласиться с условиями"} />
    </div>
  ),
  "Для продолжения нужно выбрать банк": (
    <div>
      <SubHeader text={"Для продолжения нужно выбрать банк"} />
    </div>
  ),
  "Для продолжения нужно указать номер телефонa": (
    <div>
      <SubHeader text={"Для продолжения нужно указать номер телефона"} />
    </div>
  ),
  default: (
    <div>
      <SubHeader
        text={"Проверьте правильность заполнения и попробуйте еще раз"}
      />
    </div>
  ),
};

export const ErrorScreen = ({ title, subtitle, button, onClick }) => {
  return (
    <div className={styles.incorrect}>
      <Header text={title} center />
      <SubHeader text={subtitle} center />
      <Gap height={100} />
      <button onClick={onClick}>{button}</button>
    </div>
  );
};

export const OtherErrorScreen = ({ error, onClick }) => {
  let isButton = error != "Что-то пошло не так";
  return (
    <div className={styles.incorrect}>
      <Header text={error} center />
      {errorMessages[error] || errorMessages.default}
      <Gap height={100} />
      {isButton && <button onClick={onClick}>ВЕРНУТЬСЯ</button>}
    </div>
  );
};
