import React from "react";
import styles from "../../styles/errors.module.sass";

const ErrorScreen = ({ msg = "Страница не существует" }) => {
  return (
    <div className={styles.errorScreen}>
      <div className={styles.column}>
        <h1>Ошибка</h1>
        <div className={styles.error}>{msg}</div>
      </div>
    </div>
  );
};
export default ErrorScreen;
