
import styles from '../../styles/layout.module.sass'
import { NavLink } from "react-router-dom";

const Layout = () => {
  
    return (
    
    <div className={styles.layout}>
        <a href='https://millionpuzzle.ru/privacy-policy' >Правила</a>
        <address>Почта: info@moneypuzzle.ru</address>
    </div>
    );
}



export default Layout


