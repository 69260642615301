import { useEffect, useState } from "react";
import ValidationForm from "./Validation";
import axios from "axios";
import styles from "../../styles/validation.module.sass";
import errorStyles from "../../styles/errors.module.sass";
import { main } from "../../styles/colors";
import { NavLink } from "react-router-dom";
import WinAnimation from "./Animation";
import RedesignedWinnerPage from "./RedesignedWinner";
import CardPage from "./card";
import Header from "../text/header";
import ErrorScreen from "../errorScreen/pageNotFound";
import SubHeader from "../text/subHeader";
import Gap from "../layout/gap";
//1 . validate link from qr, and render form on success
const ip = process.env.REACT_APP_IP;

const Validation = ({ match }) => {
  console.log(ip);
  const { link } = match.params;
  const [loaded, setLoaded] = useState("");
  const [code, setCode] = useState("");
  const [win, setWin] = useState("");
  const [totalSum, setTotalSum] = useState(false);
  const [pazzleCounter, setPazzleCounter] = useState(0);
  const [errMsg, setErrMsg] = useState("");
  const [dateInvalid, setDateInvalid] = useState(false);

  useEffect(() => {
    ValidateLink(link).then((res) => {
      console.log(res)
      if (res.status == 200) {
        setLoaded("start");
        if (res.data.status == 302) {
          setLoaded("validated");
          setCode(res.data.code);
          setWin(res.data.value);
        } else if (res.data.phone) {
          setTotalSum(res.data.totalSum);
          setPazzleCounter(res.data.count);
          setLoaded("payment");
          setCode(res.data.code);
          setWin(res.data.value);
        }
      } else if (res.status == 400) {
        // if()
        setLoaded("payed");
        setErrMsg(res.data.err);
        res.data.dateInvalid && setLoaded("dateInvalid");
      } else if (res.status == 404) {
        setLoaded("404");
      }
    });
  }, [link]);

  return (
    <div>
      {loaded == "start" ? (
        <ValidationForm link={link} />
      ) : loaded == "validated" ? (
        <RedesignedWinnerPage win={win} code={code} />
      ) : loaded == "payment" ? (
        <CardPage
          win={win}
          code={code}
          totalSum={totalSum}
          counter={pazzleCounter}
        />
      ) : loaded == "payed" ? (
        <ErrorScreen msg={errMsg} />
      ) : loaded == "404" ? (
        <ErrorScreen />
      ) : (
        <ErrorScreen />
      )}
    </div>
  );
};

export default Validation;

const ValidateLink = async (link) => {
  try {
    const res = await axios.get(ip + `codes/qr/${link}`);

    return res;
  } catch (err) {
    return err.response;
  }
};
